<template>
  <img class="bg" src="@/assets/lottery/tjf_rwzx_gzgzh_ewm_img.png" alt="">
</template>

<script>
export default {
};
</script>

<style lang="less">
page {
  background-color: #f7f7f7;
}

.bg {
  width: 345px;
  height: 580px;
  margin: 20px 15px;
}


</style>
